<template>
  <b-overlay :show="loading">
    <b-row>
          <b-col>
              <list-report-head :base-url="trainingElearningServiceBaseUrl" uri="/config/report-head/detail" :org-id="12">
                  {{ $t('elearning_tpm.trainer_profile') + ' ' + $t('globalTrans.details') }}
              </list-report-head>
          </b-col>
    </b-row>
    <b-row>
        <b-col lg="12" sm="12">
          <b-table-simple bordered>
                  <b-tr>
                      <b-th style="width:20%">{{ $t('globalTrans.name') }}</b-th>
                      <b-td style="width:30%">{{ ($i18n.locale=='bn') ? profileDetails.name_bn : profileDetails.name }}</b-td>
                      <b-th style="width:20%" v-if="$store.state.Auth.activeRoleId === 1 || $store.state.Auth.authUser.org_id === 1">{{ $t('org_pro.organization') }}</b-th>
                      <b-td style="width:30%" v-if="$store.state.Auth.activeRoleId === 1 || $store.state.Auth.authUser.org_id === 1">{{ ($i18n.locale=='bn') ? profileDetails.org_bn : profileDetails.org }}</b-td>
                  </b-tr>
                  <b-tr>
                      <b-th style="width:20%">{{ $t('globalTrans.designation') }}</b-th>
                      <b-td style="width:30%">{{ ($i18n.locale=='bn') ? profileDetails.designation_bn : profileDetails.designation }}</b-td>
                      <b-th style="width:20%">{{ $t('globalTrans.mobile') }}</b-th>
                      <b-td style="width:30%">{{ ($i18n.locale === 'bn' ? '০' : '0') + $n(profileDetails.mobile, { useGrouping: false }) }}</b-td>
                  </b-tr>
                  <b-tr>
                      <b-th style="width:20%">{{ $t('elearning_tpm.working_field') }}</b-th>
                      <b-td style="width:30%" colspan="3">{{ ($i18n.locale=='bn') ? profileDetails.working_field_bn : profileDetails.working_field }}</b-td>
                  </b-tr>
          </b-table-simple>
          <b-row>
              <b-col lg="12" sm="12" class="text-center mb-4">
                  <h5 class='complain-title'>{{ $t('elearning_tpm.document') }}</h5>
              </b-col>
          </b-row>
          <b-table-simple bordered>
              <b-tr>
                  <b-th style="width:50%">{{ $t('elearning_tpm.document_name') }}</b-th>
                  <b-th style="width:50%">{{ $t('globalTrans.attachment') }}</b-th>
              </b-tr>
              <b-tr v-for="(item,index) in profileDetails.trainer_profile_documents" :key="index">
                  <b-td style="width:50%">{{ ($i18n.locale=='en') ? item.document_name : item.document_name_bn }}</b-td>
                  <b-td style="width:50%"><a target = '_blank' :href="baseUrl + 'download-attachment?file=uploads/trainer-profile/original/' + item.trainer_profile_id + '/' + item.attachment" title="Attachment" class="mr-3" download><i class="ri-download-cloud-fill"></i></a></b-td>
              </b-tr>
          </b-table-simple>
        </b-col>
    </b-row>
  </b-overlay>
</template>
<script>
import { trainingElearningServiceBaseUrl } from '@/config/api_config'
import ListReportHead from '@/components/custom/ListReportHeadTraining.vue'
import ExportPdf from './export_pdf_details_trainer_profile'
export default {
    components: {
      ListReportHead
    },
  name: 'FormLayout',
  props: ['item'],
  created () {
    this.profileDetails = this.item
  },
  data () {
    return {
      profileDetails: [],
      slOffset: 1,
      baseUrl: trainingElearningServiceBaseUrl,
      trainingElearningServiceBaseUrl: trainingElearningServiceBaseUrl
    }
  },
  computed: {
    loading () {
      return this.$store.state.commonObj.loading
    }
  },
  methods: {
    pdfExport () {
        const reportTitle = this.$i18n.locale === 'en' ? 'Trainer Profile Details' : 'প্রশিক্ষকের প্রোফাইল বিবরণ'
        ExportPdf.exportPdfDetails(trainingElearningServiceBaseUrl, '/config/report-head/detail', 12, reportTitle, this.profileDetails, this)
    }
  }
}
</script>
<style>
  .hidden_header {
    display: none
  }
  .card-border {
    border: 1px solid #b9bdc1;
    box-shadow: 1px 1px 6px -1px grey;
    background-color: #dee2e6;
  }
  [dir=ltr][mode=light] .card-body {
    flex: 1 1 auto;
    min-height: 1px;
    padding: 10px;
  }
  .report-name{
    font-weight: bold !important;
    text-transform: uppercase;
  }
  .my-btn{
    padding: 2px !important;
  }
</style>
