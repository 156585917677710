<template>
  <div class="inner-section">
         <card>
          <template v-slot:searchHeaderTitle>
            <h4 class="card-title">{{$t('elearning_tpm.trainer_document')}} {{ $t('globalTrans.search') }}</h4>
          </template>
            <template v-slot:searchBody>
                <b-row>
                  <b-col lg="5" sm="12">
                    <b-form-group
                        class="row"
                        label-cols-sm="4"
                        label-for="mobile"
                    >
                      <template v-slot:label>
                        {{ $t('globalTrans.mobile')}}
                      </template>
                      <b-form-input
                          type="number"
                          id="mobile"
                          v-model="search.mobile"
                      ></b-form-input>
                    </b-form-group>
                  </b-col>
                  <b-col lg="6" sm="12" v-if="$store.state.Auth.activeRoleId === 1 || $store.state.Auth.authUser.org_id === 1">
                    <b-form-group
                        class="row"
                        label-cols-sm="4"
                        :label="$t('elearning_config.organization')"
                        label-for="organization"
                    >
                      <v-select name="organization"
                                v-model="search.org_id"
                                label="text"
                                :reduce="item => item.value"
                                :options= orgList
                                :placeholder="$t('globalTrans.select')"
                      />
                    </b-form-group>
                  </b-col>
                  <b-col lg="2" sm="12" class="text-right">
                      <b-button type="button" variant="primary" @click="searchData">{{ $t('globalTrans.search')}}</b-button>
                  </b-col>
                </b-row>
            </template>
        </card>
        <body-card>
          <template v-slot:headerTitle>
              <h4 class="card-title">{{$t('elearning_tpm.trainer_document')}} {{ $t('globalTrans.list') }}</h4>
          </template>
          <template v-slot:headerAction>
              <b-button class="btn_add_new mr-2" @click="pdfExport">
                <i class="fas fa-print"></i>{{  $t('globalTrans.print') }}
              </b-button>
              <b-button class="btn_add_new" v-b-modal.modal-4 @click="resetId">
                <i class="fas fa-plus"></i>{{  $t('globalTrans.add_new') }}
              </b-button>
          </template>
          <template v-slot:body>
                <b-overlay :show="loadingState">
                    <b-row>
                        <b-col sm="12">
                        <div class="quick-filter-wrapper">
                            <div class="quick-filter-left">
                            <b-dropdown split split-variant="outline-secondary" size="sm" id="dropdown-form" :text="$t('globalTrans.column_visibility')" ref="dropdown">
                                <b-dropdown-form>
                                <div
                                    class="form-group"
                                    v-for="(field, index) in labelData"
                                    :key="index"
                                    >
                                    <b-form-checkbox
                                        :id="'checkbox-' + field.label"
                                        v-model="field.show"
                                        :name="'checkbox-' + field.label"
                                        value=1
                                        unchecked-value=0
                                    >
                                        {{ ($i18n.locale==='bn') ? field.label : field.label }}
                                    </b-form-checkbox>
                                </div>
                                </b-dropdown-form>
                            </b-dropdown>
                            </div>
                            <div class="quick-filter-right">
                            <b-form-group
                                :label="$t('menu.perpage')"
                                label-for="per-page-select"
                            >
                                <b-form-select
                                id="per-page-select"
                                v-model="search.limit"
                                :options="pageOptions"
                                size="sm"
                                ></b-form-select>
                            </b-form-group>
                            </div>
                        </div>
                        </b-col>
                    </b-row>
                    <b-row>
                      <b-col md="12" class="table-responsive">
                        <slot v-if ="columns.filter(item => item.show === '1').length > 0">
                          <b-table :sort-by.sync="sortBy" :sort-desc.sync="sortDesc" head-variant="primary" class="tg" :items="listData" :emptyText="$t('globalTrans.noDataFound')" show-empty bordered hover :fields="columns.filter(item => item.show === '1').sort((a, b) => { return a.order - b.order })">
                            <template v-slot:cell(serial)="data">
                              {{ $n(data.item.serial + pagination.slOffset) }}
                            </template>
                            <template v-slot:cell(mobile)="data">
                              {{ $i18n.locale === 'bn' ? '০' : '0' }}{{ $n(data.item.mobile, { useGrouping: false }) }}
                            </template>
                            <template v-slot:cell(status)="data">
                              <span class="badge badge-success" v-if="data.item.status == 1">{{$t('globalTrans.active')}}</span>
                              <span class="badge badge-danger" v-else>{{$t('globalTrans.inactive')}}</span>
                            </template>
                            <template v-slot:cell(action)="data">
                              <a href="javascript:" class="btn_table_action table_action_view" title="View Details" v-b-modal.modal-5 @click="details(data.item)"><i class="fas fa-eye "></i></a>
                              <a href="javascript:" class="btn_table_action table_action_edit" v-b-modal.modal-4  @click="edit(data.item)"><i class="ri-ball-pen-fill m-0"></i></a>
                              <span v-if="data.item.status === 1">
                                      <a href="javascript:" class="btn_table_action table_action_status" @click="remove(data.item)"><i class="fas fa-toggle-on"></i></a>
                                  </span>
                              <span v-if="data.item.status === 2">
                                      <a href="javascript:" class="btn_table_action table_action_toggle" @click="remove(data.item)"><i class="fas fa-toggle-off"></i></a>
                                  </span>
                            </template>
                          </b-table>
                        </slot>
                        <div class="pagination-wrapper mt-3" v-if="columns.filter(item => item.show === '1').length > 0">
                          <b-pagination
                            v-model="pagination.currentPage"
                            :perPage="search.limit"
                            :total-rows="pagination.totalRows"
                            @input="searchData"
                          />
                        </div>
                      </b-col>
                    </b-row>
                </b-overlay>
            </template>
        </body-card>
        <b-modal id="modal-4" size="xl" :title="formTitle" hide-footer :ok-title="$t('globalTrans.close')" ok-only ok-variant="danger">
            <Form :id="editItemId" :key="editItemId"/>
        </b-modal>
        <b-modal id="modal-5" size="lg" hide-footer :title="$t('elearning_tpm.trainer_document') + ' ' + this.$t('globalTrans.details')" :ok-title="$t('globalTrans.close')" ok-only ok-variant="danger">
          <template #modal-title class="flex-grow-1">
            {{ $t('elearning_iabm.training_applications') }}
            <b-button variant="success" @click="pdfExportDetails" class="mr-2 float-right">
              <i class="fas fa-print"></i> {{  $t('globalTrans.print') }}
            </b-button>
          </template>
          <Details :item="item" :key="id" ref="details"></Details>
      </b-modal>
    </div>
</template>
<script>

import Form from './Form'
import RestApi, { trainingElearningServiceBaseUrl } from '@/config/api_config'
import { trainerProfileList, trainerProfileToggleStatus } from '../../api/routes'
import ModalBaseMasterList from '@/mixins/list'
import ExportPdf from './export_pdf_details'
import Details from './Details'
import Store from '@/store'

export default {
    mixins: [ModalBaseMasterList],
    components: {
      Form, Details
    },
    data () {
      return {
        sortBy: '',
        sortDirection: '',
        sortDesc: '',
        search: {
          org_id: [],
          mobile: '',
          limit: 20
        },
        id: 0,
        item: '',
        labelData: [
          { labels: 'globalTrans.sl_no', class: 'text-left', sortable: true, stickyColumn: true, show: '1', order: 1, thStyle: { width: '10%' } },
          { labels: 'globalTrans.name', class: 'text-left', sortable: true, stickyColumn: true, show: '1', order: 2, thStyle: { width: '30%' } },
          { labels: 'elearning_config.organization', class: 'text-left', sortable: true, stickyColumn: true, show: '1', order: 3, thStyle: { width: '35%' } },
          { labels: 'globalTrans.designation', class: 'text-left', sortable: true, stickyColumn: true, show: '1', order: 4, thStyle: { width: '35%' } },
          { labels: 'globalTrans.mobile', class: 'text-left', sortable: true, stickyColumn: true, show: '1', order: 5, thStyle: { width: '35%' } },
          { labels: 'elearning_tpm.working_field', class: 'text-left', sortable: true, stickyColumn: true, show: '1', order: 6, thStyle: { width: '35%' } },
          { labels: 'globalTrans.status', class: 'text-left', sortable: true, stickyColumn: true, show: '1', order: 7, thStyle: { width: '10%' } },
          { labels: 'globalTrans.action', class: 'text-left', sortable: true, stickyColumn: true, show: '1', order: 8, thStyle: { width: '10%' } }
        ]
      }
    },
    computed: {
      orgList: function () {
        return this.$store.state.CommonService.commonObj.componentOrgList.filter(item => item.status === 1)
      },
      formTitle () {
        return (this.editItemId === 0) ? this.$t('elearning_tpm.trainer_document') + ' ' + this.$t('globalTrans.entry') : this.$t('elearning_tpm.trainer_document') + ' ' + this.$t('globalTrans.update')
      },
      columns () {
        const labelData = this.labelData
        const labels = labelData.map((item, index) => {
          return Object.assign(item, { label: this.$t(item.labels, this.$i18n.locale) })
        })
        let keys = []
        if (this.$i18n.locale === 'bn') {
          keys = [
            { key: 'serial' },
            { key: 'name_bn' },
            { key: 'org_bn' },
            { key: 'designation_bn' },
            { key: 'mobile' },
            { key: 'working_field_bn' },
            { key: 'status' },
            { key: 'action' }
          ]
        } else {
          keys = [
          { key: 'serial' },
          { key: 'name' },
          { key: 'org' },
          { key: 'designation' },
          { key: 'mobile' },
          { key: 'working_field' },
          { key: 'status' },
          { key: 'action' }
          ]
        }
        return labels.map((item, index) => {
          return Object.assign(item, keys[index])
        })
      },
      pageOptions () {
        return this.$store.state.commonObj.pageOptions
      }
    },
    async created () {
      this.search = Object.assign({}, this.search, {
        // org_id: this.$store.state.dataFilters.orgId
      })
      this.loadData()
    },
    methods: {
      async searchData () {
        this.loadData()
      },
      details (item) {
        this.item = item
      },
      remove (item) {
        this.changeStatus(trainingElearningServiceBaseUrl, trainerProfileToggleStatus, item, 'training_e_learning', 'trainerEvaluationList')
      },
      changeStatus (baseUrl, uri, item, destination = null, dropdownName = null) {
        Store.dispatch('mutateCommonProperties', { loading: true, listReload: false })
        RestApi.deleteData(baseUrl, `${uri}/${item.id}`).then(response => {
          if (response.success) {
          /** The following line commented as list is reloaded */
          // Store.dispatch('toggleStatus', item)
          if (destination !== null && dropdownName !== null) {
            Store.dispatch('toggleDropdownItemStatus', { itemId: item.id, destination: destination, dropdownName: dropdownName })
          }
          window.vm.$toast.success({
            title: 'Success',
            message: 'Data Updated Successfully',
            color: '#D6E09B'
          })
          } else {
            window.vm.$toast.error({
              title: 'Error',
              message: 'Operation failed! Please, try again.'
            })
          }
          Store.dispatch('mutateCommonProperties', { loading: false, listReload: true })
        })
      },
      loadData () {
        const params = Object.assign({}, this.search, { page: this.pagination.currentPage, per_page: this.pagination.perPage })
        this.$store.dispatch('mutateCommonProperties', { loading: true, listReload: false })
        RestApi.getData(trainingElearningServiceBaseUrl, trainerProfileList, params).then(response => {
        if (response.success) {
          this.$store.dispatch('setList', this.getCustomDataList(response.data.data))
          this.paginationData(response.data, this.search.limit)
        }
        this.$store.dispatch('mutateCommonProperties', { loading: false, listReload: false })
        })
      },
      getCustomDataList (data) {
        const listData = data.map((item, index) => {
        const orgObj = this.$store.state.CommonService.commonObj.componentOrgList.find(doc => doc.value === parseInt(item.org_id))
        const orgData = {}
        if (typeof orgObj !== 'undefined') {
          orgData.org = orgObj.text_en
          orgData.org_bn = orgObj.text_bn
        } else {
          orgData.org = ''
          orgData.org_bn = ''
        }
        const designationObj = this.$store.state.CommonService.commonObj.designationList.find(designation => designation.value === parseInt(item.designation_id))
        const designationData = {}
        if (typeof designationObj !== 'undefined') {
          designationData.designation = designationObj.text_en
          designationData.designation_bn = designationObj.text_bn
        } else {
          designationData.designation = ''
          designationData.designation_bn = ''
        }
        return Object.assign({}, { serial: index }, item, orgData, designationData)
        })
        return listData
      },
      pdfExportDetails () {
        this.$refs.details.pdfExport()
      },
      pdfExport () {
        const reportTitle = this.$i18n.locale === 'en' ? 'Trainer Profiles' : 'প্রশিক্ষকের প্রোফাইল'
        ExportPdf.exportPdfDetails(trainingElearningServiceBaseUrl, '/config/report-head/detail', 12, reportTitle, this)
      }
    }
}
</script>
<style>
  .modal-title {
    flex-grow: 1 !important;
  }
</style>
