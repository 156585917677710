
<template v-slot:body>
  <b-overlay :show="isLoading">
    <b-row>
      <b-col lg="12" sm="12">
        <ValidationObserver ref="form"  v-slot="{ handleSubmit, reset }">
          <b-form  @submit.prevent="handleSubmit(createData)" @reset.prevent="reset" >
            <b-row>
              <b-col lg="6" md="6" sm="12" xs="12">
                <ValidationProvider name="Mobile" vid='mobile' rules="required">
                  <b-form-group
                      class="row"
                      label-cols-sm="4"
                      label-for="mobile"
                      slot-scope="{ valid, errors }"
                  >
                    <template v-slot:label>
                      {{ $t('globalTrans.mobile')}} <span class="text-danger">*</span>
                    </template>
                    <b-form-input
                        type="number"
                        id="mobile"
                        v-model="formData.mobile"
                        :state="errors[0] ? false : (valid ? true : null)"
                        @change="getPersonalInfo"
                    ></b-form-input>
                    <div class="invalid-feedback">
                      {{ errors[0] }}
                    </div>
                  </b-form-group>
                </ValidationProvider>
              </b-col>
              <b-col lg="6" md="6" sm="12" xs="12">
                <ValidationProvider name="Name" vid='name' rules="">
                  <b-form-group
                      class="row"
                      label-cols-sm="4"
                      label-for="name"
                      slot-scope="{ valid, errors }"
                  >
                    <template v-slot:label>
                      {{ $t('globalTrans.name') }}
                    </template>
                    <b-form-input
                        id="name"
                        disabled
                        v-model="formData.name"
                        :state="errors[0] ? false : (valid ? true : null)"
                    ></b-form-input>
                    <div class="invalid-feedback">
                      {{ errors[0] }}
                    </div>
                  </b-form-group>
                </ValidationProvider>
              </b-col>
            </b-row>
            <b-row>
              <b-col lg="6" md="6" sm="12" xs="12" v-if="$store.state.Auth.activeRoleId === 1 || $store.state.Auth.authUser.org_id === 1">
                <ValidationProvider name="Organization"  vid="org_id" rules="">
                  <b-form-group
                      class="row"
                      label-cols-sm="4"
                      label-for="org_id"
                      slot-scope="{ valid, errors }"
                  >
                    <template v-slot:label>
                      {{$t('elearning_config.organization')}}
                    </template>
                    <b-form-select
                        plain
                        v-model="formData.org_id"
                        :options="orgList"
                        id="org_id"
                        :state="errors[0] ? false : (valid ? true : null)"
                        disabled
                    >
                      <template v-slot:first>
                        <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                      </template>
                    </b-form-select>
                    <div class="invalid-feedback">
                      {{ errors[0] }}
                    </div>
                  </b-form-group>
                </ValidationProvider>
              </b-col>
              <b-col lg="6" md="6" sm="12" xs="12">
                <ValidationProvider name="Designation" vid="designation_id" rules="">
                  <b-form-group
                      class="row"
                      label-cols-sm="4"
                      label-for="designation_id"
                      slot-scope="{ valid, errors }"
                  >
                    <template v-slot:label>
                      {{ $t('externalTraining.designation')}}
                    </template>
                    <b-form-select
                        plain
                        v-model="formData.designation_id"
                        :options="designationList"
                        id="designation_id"
                        :state="errors[0] ? false : (valid ? true : null)"
                        disabled
                    >
                      <template v-slot:first>
                        <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                      </template>
                    </b-form-select>
                    <div class="invalid-feedback">
                      {{ errors[0] }}
                    </div>
                  </b-form-group>
                </ValidationProvider>
              </b-col>
            </b-row>
            <b-row>
              <b-col lg="6" md="6" sm="12" xs="12">
                <ValidationProvider name="Email" vid='email' rules="">
                  <b-form-group
                      class="row"
                      label-cols-sm="4"
                      label-for="email"
                      slot-scope="{ valid, errors }"
                  >
                    <template v-slot:label>
                      {{ $t('externalTraining.email')}}
                    </template>
                    <b-form-input
                        readonly
                        id="email"
                        v-model="formData.email"
                        :state="errors[0] ? false : (valid ? true : null)"
                    ></b-form-input>
                    <div class="invalid-feedback">
                      {{ errors[0] }}
                    </div>
                  </b-form-group>
                </ValidationProvider>
              </b-col>
            </b-row>
            <b-row>
              <b-col lg="6" md="6" sm="12" xs="12">
                <ValidationProvider name="Working Field (EN)" vid='working_field' rules="required">
                  <b-form-group
                      class="row"
                      label-cols-sm="4"
                      label-for="working_field"
                      slot-scope="{ valid, errors }"
                  >
                    <template v-slot:label>
                      {{ $t('elearning_tpm.working_field_en') }}  <span class="text-danger">*</span>
                    </template>
                    <b-form-input
                        id="name"
                        v-model="formData.working_field"
                        :state="errors[0] ? false : (valid ? true : null)"
                    ></b-form-input>
                    <div class="invalid-feedback">
                      {{ errors[0] }}
                    </div>
                  </b-form-group>
                </ValidationProvider>
              </b-col>
              <b-col lg="6" md="6" sm="12" xs="12">
                <ValidationProvider name="Working Field (BN)" vid='working_field_bn' rules="required">
                  <b-form-group
                      class="row"
                      label-cols-sm="4"
                      label-for="working_field_bn"
                      slot-scope="{ valid, errors }"
                  >
                    <template v-slot:label>
                      {{ $t('elearning_tpm.working_field_bn') }}  <span class="text-danger">*</span>
                    </template>
                    <b-form-input
                        id="working_field_bn"
                        v-model="formData.working_field_bn"
                        :state="errors[0] ? false : (valid ? true : null)"
                    ></b-form-input>
                    <div class="invalid-feedback">
                      {{ errors[0] }}
                    </div>
                  </b-form-group>
                </ValidationProvider>
              </b-col>
            </b-row>
            <b-row v-for="(detail, index) in formData.trainer_profile_documents" :key="index" class="mt-3">
              <b-col md="10">
                <b-row>
                  <b-col lg="4" md="4" sm="12" xs="12">
                    <ValidationProvider name="Document Name (En)" vid="document_name" rules="">
                      <b-form-group
                          class="row"
                          label-cols-sm="12"
                          label-for="document_name"
                          slot-scope="{ valid, errors }"
                      >
                        <template v-slot:label>
                          {{$t('elearning_tpm.document_name')}}
                        </template>
                        <b-form-input
                            id="document_name"
                            v-model="detail.document_name"
                            :state="errors[0] ? false : (valid ? true : null)"
                        ></b-form-input>
                        <div class="invalid-feedback">
                          {{ errors[0] }}
                        </div>
                      </b-form-group>
                    </ValidationProvider>
                  </b-col>
                  <b-col lg="4" md="4" sm="12" xs="12">
                    <ValidationProvider name="Document Name (Bn)" vid="document_name_bn" rules="">
                      <b-form-group
                          class="row"
                          label-cols-sm="12"
                          label-for="document_name_bn"
                          slot-scope="{ valid, errors }"
                      >
                        <template v-slot:label>
                          {{$t('elearning_tpm.document_name_bn')}}
                        </template>
                        <b-form-input
                            id="document_name_bn"
                            v-model="detail.document_name_bn"
                            :state="errors[0] ? false : (valid ? true : null)"
                        ></b-form-input>
                        <div class="invalid-feedback">
                          {{ errors[0] }}
                        </div>
                      </b-form-group>
                    </ValidationProvider>
                  </b-col>
                  <b-col lg="4" md="4" sm="12" xs="12">
                    <ValidationProvider name="Attachment" :vid="'trainer_profile_documents.'+index+'.attachment'">
                      <b-form-group
                          class="row"
                          label-cols-sm="12"
                          label-for="attachment"
                          slot-scope="{ valid, errors }"
                      >
                        <template v-slot:label>
                          {{ $t('globalTrans.attachment') }}
                        </template>
                        <b-form-file
                            :id="'trainer_profile_documents.'+index+'.attachment'"
                            :ref="'trainer_profile_documents.'+index+'.attachment'"
                            v-model="detail.attachment"
                            :state="errors[0] ? false : (valid ? true : null)"
                        ></b-form-file>
                        <div class="invalid-feedback">
                          {{ errors[0] }}
                        </div>
                      </b-form-group>
                    </ValidationProvider>
                  </b-col>
                </b-row>
              </b-col>
              <b-col xl="2" lg="2" sm="4" class="text-right mt-5">
                <b-button v-show="index == formData.trainer_profile_documents.length-1" variant=" iq-bg-success" size="sm" @click="addItem()"><i class="ri-add-line m-0"></i>Add</b-button>
                <b-button v-show="index || ( !index && formData.trainer_profile_documents.length > 1)" variant=" iq-bg-danger" size="sm" @click="remove(index)"><i class="ri-delete-bin-line m-0"></i></b-button>
              </b-col>
            </b-row>
            <div class="row">
              <div class="col-sm-3"></div>
              <div class="col text-right">
                <b-button type="submit" variant="primary" class="mr-2">{{ saveBtnName }}</b-button>
                &nbsp;
                <b-button variant="danger" class="mr-1" @click="$bvModal.hide('modal-4')">{{ $t('globalTrans.cancel') }}</b-button>
              </div>
            </div>
          </b-form>
        </ValidationObserver>
      </b-col>
    </b-row>
  </b-overlay>
</template>
<script>

import { ValidationObserver, ValidationProvider } from 'vee-validate'
import RestApi, { trainingElearningServiceBaseUrl } from '@/config/api_config'
import { trainerProfileStore, trainerProfileUpdate, trainerProfilePersonalInfo } from '../../api/routes'
import flatpickr from 'flatpickr'

export default {
  props: ['id'],
  components: {
    ValidationObserver,
    ValidationProvider
  },
  created () {
    if (this.id) {
      const tmp = this.getFormData()
      this.formData = tmp
      this.formData.trainer_profile_documents.map(item => {
        item.attachment = ''
      })
    } else {
       this.formData = Object.assign({}, this.formData, {
        org_id: this.$store.state.dataFilters.orgId
      })
    }
  },
  mounted () {
    flatpickr('.fromDate', {})
  },
  data () {
    return {
      saveBtnName: this.id ? this.$t('globalTrans.update') : this.$t('globalTrans.save'),
      formData: {
        id: '',
        mobile: '',
        name: '',
        email: '',
        org_id: 0,
        designation_id: 0,
        working_field: '',
        working_field_bn: '',
        status: 1,
        trainer_profile_documents: [
          {
            document_name: '',
            document_name_bn: '',
            attachment: null
          }
        ]
      },
      isLoading: false
    }
  },
  computed: {
    orgList: function () {
      return this.$store.state.CommonService.commonObj.componentOrgList.filter(item => item.status === 1)
    },
    currentLocale: function () {
      return this.$i18n.locale
    },
    designationList () {
      return this.$store.state.commonObj.designationList.filter(item => item.status === 0)
    }
  },
  watch: {
  },
  methods: {
    getFormData () {
      const tmpData = this.$store.state.list.find(item => item.id === this.id)
      return JSON.parse(JSON.stringify(tmpData))
    },
    async getPersonalInfo () {
        const serchData = {
            org_id: this.$store.state.dataFilters.orgId
        }
      this.isLoading = true
      const result = await RestApi.postData(trainingElearningServiceBaseUrl, trainerProfilePersonalInfo, { mobile: this.formData.mobile }, serchData)

      if (result.success) {
        this.formData.name = this.currentLocale === 'en' ? result.data.name : result.data.name_bn
        this.formData.email = result.data.email
        this.formData.org_id = result.data.org_id
        this.formData.designation_id = result.data.designation_id
        this.formdata = Object.assign({}, this.formData, {
          name: result.data.name,
          email: result.data.email,
          org_id: result.data.org_id,
          designation_id: result.data.designation_id
        })
      } else {
        this.formData.name = ''
        this.formData.email = ''
        this.formData.org_id = ''
        this.formData.designation_id = ''
        this.$toast.error({
          title: 'Error',
          message: this.$t('elearning_iabm.personalInfoNotFoundMsg'),
          color: '#D6E09B'
        })
      }
      this.isLoading = false
    },
    async createData () {
      this.isLoading = true
      this.$store.dispatch('mutateCommonProperties', { loading: true })
      let result = null
      const loadinState = { loading: false, listReload: false }
      var formData = new FormData()
      formData.append('mobile', this.formData.mobile)
      formData.append('working_field', this.formData.working_field)
      formData.append('working_field_bn', this.formData.working_field_bn)
      this.formData.trainer_profile_documents.filter((item, index) => {
        formData.append('trainer_profile_documents[' + index + '][document_name]', item.document_name)
        formData.append('trainer_profile_documents[' + index + '][document_name_bn]', item.document_name_bn)
        formData.append('trainer_profile_documents[' + index + '][attachment]', item.attachment)
      })
      if (this.formData.id) {
        result = await RestApi.postData(trainingElearningServiceBaseUrl, `${trainerProfileUpdate}/${this.id}`, formData)
      } else {
        result = await RestApi.postData(trainingElearningServiceBaseUrl, trainerProfileStore, formData)
      }

      loadinState.listReload = true

      this.$store.dispatch('mutateCommonProperties', loadinState)

      if (result.success) {
        this.$store.dispatch('TrainingElearning/mutateTrainingElearningCommonProperties', { hasDropdownLoaded: false })
        this.$toast.success({
          title: 'Success',
          message: this.id ? this.$t('globalTrans.update_msg') : this.$t('globalTrans.save_msg'),
          color: '#D6E09B'
        })

        this.$bvModal.hide('modal-4')
      } else {
        this.$refs.form.setErrors(result.errors)
        this.$toast.error({
            title: 'Error',
            message: result.message,
            color: '#D6E09B'
        })
      }
      this.isLoading = false
    },
    addItem () {
      const obj = {
        document_name: '',
        document_name_bn: '',
        attachment: null
      }
      const key1 = parseInt(this.formData.trainer_profile_documents.length - 1)
      const item = this.formData.trainer_profile_documents[key1]
      let isEmpty = true
      // check whether form fields are filled
      Object.keys(item).map(key => {
        if (!item.id) {
            if (item[key] === '' || item[key] === 0) {
                isEmpty = false
            }
        }
      })
      if (isEmpty === true) {
        this.formData.trainer_profile_documents.push(obj)
      }
    },
    remove (key) {
      this.formData.trainer_profile_documents.splice(key, 1)
    }
  }
}
</script>
